<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card">
        <div class="card-body">
            <div class="form-check" v-for="t in tables" :key="t">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selecetd" :value="t">
                {{ t }}
              </label>
            </div>
        </div>
        <div class="card-footer text-center">
            <button class="btn btn-primary" @click="save()">
                <i class="fa fa-trash"></i>
                حذف الجدوال
            </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            selecetd: [],
            tables: []
        }
    },
    created(){
        if(!checkPer('*')){
            this.$router.push('/pers')
            return;
        }
        var g = this;
        $.post(api + '/admin/web/tables', {
            jwt: g.user.jwt
        }).then(function(r){
            g.tables = r.response
        })
    },
    methods: {
        save(){
            var g = this;
            $.post(api + '/admin/web/tables/delete', {
                jwt: g.user.jwt,
                selected: g.selecetd
            }).then(function(r){
               location.reload()
            })
        }
    }
}
</script>

<style>

</style>